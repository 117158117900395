import React, { useEffect, useState } from "react";
import RssReader from "../../../assets/RssReader";
import { useParams } from "react-router-dom";
import { PODCASTSLIST } from "../../../helper/Const";
import Loading from "../../../assets/Loading";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import FooterSecondary from "../../../footer/FooterSecondary";
import Navbar2 from "../../../navbar/Navbar2";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import logoRed from "../../../assets/AOCABOPONTO_logoRED.png"

function Episode() {
  const [podcastData, setPodcastData] = useState(null);
  const [epCount, setEpCount] = useState(null);

  let { podcast, episode } = useParams();

  const customStyles = {
    width: "76.5vw",
    border: "2.5px solid black",
    height: "auto",
    padding: "5px 15px",
  };

  const customStyles2 = {
    width: "76.5vw",
    border: "2.5px solid black",
    height: "auto",
    padding: "5px 15px",
    display: "none"
  };

  useEffect(() => {
    RssReader(PODCASTSLIST[podcast].url, setPodcastData);
  }, []);

  useEffect(() => {
    if (podcastData !== null) {
      setEpCount(podcastData.item.length - episode);
    }
  }, [podcastData]);

  if (epCount !== null) {
    return (
      <div>
        <Navbar2 />
        <h1
          className="podcast_title"
          style={{
            textAlign: "center",
            fontFamily: "SlencoBlack",
            fontSize: "80px",
            marginTop: "2%",
          }}
        >
          {PODCASTSLIST[podcast].name}
        </h1>
        <Container
          fluid
          style={{
            paddingTop: "1vh",
            width: "80%",
            marginBottom: "10vh",
          }}
        >
          <Row className="justify-content-center">
            <Col
              xs="auto"
              lg={1}
              className="circle3"
              style={{ marginRight: "2rem", marginLeft: "2rem" }}
            >
              <img
                // src={podcastData ? podcastData.image.url : ""}
                src={logoRed}
                className="App-logo"
                alt="Ao Cabo Teatro"
                style={{
                  border: "5px solid",
                  height: "35vmin",
                  borderRadius: "100%",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              ></img>
            </Col>
            <Col xs="auto" lg={8}>
              <p
                style={{
                  fontFamily: "SlencoBold",
                  fontSize: "26px",
                  marginBottom: "2%",
                }}
              >
                {podcastData ? podcastData.item[0][epCount].title : ""}
              </p>
              <p
                style={{
                  fontFamily: "SlencoLight",
                  fontSize: "13px",
                  marginBottom: "2.5%",
                }}
                dangerouslySetInnerHTML={
                  podcastData
                    ? { __html: podcastData.item[0][epCount].description }
                    : { __html: "" }
                }
              ></p>
            </Col>
          </Row>
          <AudioPlayer
          className="desktop"
            style={customStyles}
            customAdditionalControls={[]}
            layout="horizontal"
            progressJumpStep={15000}
            showFilledVolume={true}
            src={
              podcastData ? podcastData.item[0][epCount].enclosure["@_url"] : ""
            }
          />
           <AudioPlayer
            className="mobile"
            style={customStyles2}
            customAdditionalControls={[]}
            layout="horizontal"
            progressJumpStep={15000}
            showJumpControls={false}
            showSkipControls={false}
            src={
              podcastData ? podcastData.item[0][epCount].enclosure["@_url"] : ""
            }
          />
        </Container>
        <FooterSecondary />
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default Episode;
