import React from "react";
import { Link } from "react-router-dom";
import FooterSecondary from "../footer/FooterSecondary";
import Navbar2 from "../navbar/Navbar2";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

function Audio() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();
  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        {t("Audio.0")}
      </h1>
      <Container
        fluid
        style={{
          width: "85%",
          paddingTop: "15vh",
          marginBottom: "10vh",
        }}
      >
        <Row>
          <Col>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/podcasts"
            >
              <div className="circle">
                <div
                  style={{
                    textAlign: "center",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "SlencoBold",
                      fontSize: "45px",
                      marginBottom: "-0.5rem",
                    }}
                  >
                    podcasts
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          <Col sm>
            <div className="circle_black">
              <div
                style={{
                  textAlign: "center",
                  display: "table-cell",
                  verticalAlign: "middle",
                }}
              >
                <p
                  style={{
                    fontFamily: "SlencoBold",
                    fontSize: "45px",
                    color: "white",
                    marginBottom: "-0.5rem",
                  }}
                >
                  {t("Audio.1")}
                </p>
              </div>
            </div>
          </Col>
          <Col sm>
            <div className="circle_black">
              <div
                style={{
                  textAlign: "center",
                  display: "table-cell",
                  verticalAlign: "middle",
                }}
              >
                <p
                  style={{
                    fontFamily: "SlencoBold",
                    fontSize: "45px",
                    color: "white",
                    marginBottom: "-0.5rem",
                  }}
                >
                  {t("Audio.1")}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Audio;
