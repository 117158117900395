import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";

function EquipaDetail(props) {
  return (
    <Col md={3}>
      <div className="circle_team">
        <div
          style={{
            textAlign: "center",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={"/equipa/" + props.item.name}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "SlencoBlack",
                fontSize: "60px",
                lineHeight: "50px",
                marginBottom: "0.5rem",
                textTransform: "lowercase",
              }}
            >
              {props.item.name}
            </p>
          </Link>
        </div>
      </div>
    </Col>
  );
}

export default EquipaDetail;
