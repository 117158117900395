import axios from "axios";
import {XMLParser} from "fast-xml-parser";

function RssReader(url, setter) {

  const options = {
    ignoreAttributes: false,
  };

    axios.get(url).then((response) => {
    let parser = new XMLParser(options);
    let data = parser.parse(response.data);
    let finalData = {item:[], image:""};
    if (typeof data.rss.channel.item === "object") {
      finalData.item.push(data.rss.channel.item)
      finalData.image = data.rss.channel.image
    } else {
      finalData = data.rss.channel
    }

    setter(finalData)

  });


}

export default RssReader