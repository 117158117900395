export const PODCASTSLIST = {
    "aofimeaocabo": {
        "name": "ao fim e ao cabo",
        "url": "https://anchor.fm/s/785bdde4/podcast/rss",
        "desc_pt": "Podcast de entrevistas e conversas com alguns dos colaboradores dos projectos da Ao Cabo Teatro. A jornalista Mariana Duarte e, às vezes, Luís Araújo (director artístico da Ao Cabo) conversam com os convidados, desvendando mais um pouco os projectos e os seus intervenientes.",
        "desc_en": "Podcast of interviews and conversations with some of the collaborators of Ao Cabo Teatro projects.  Journalist Mariana Duarte and, at times, Luís Araújo (artistic director of Ao Cabo) talk to the guests, revealing a little more about the projects and their actors.",
        "desc_es": "Podcast de entrevistas y conversaciones con algunos de los colaboradores de los proyectos de Ao Cabo Teatro. La periodista Mariana Duarte y, a veces, Luís Araújo (director artístico de Ao Cabo) charlan con los invitados, desenmarañando un poco más de los proyectos y sus participantes.",
    },
    "brevemente": {
        "name": "brevemente",
        "url": ""
    },
    "brevemente2": {
        "name": "brevemente",
        "url": ""
    }
}