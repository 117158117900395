import React from "react";
import "./FooterCSS.css";
import { motion } from "framer-motion";
import instagram from "../assets/instagram.png";
import facebook from "../assets/facebook.png";
import vimeo from "../assets/vimeo.png";
import email from "../assets/email.png";

function FooterMain() {
  return (
    <footer className="footer-basic">
      <div className="social">
        <a className="footer-a" href="https://www.instagram.com/aocaboteatro/">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={instagram}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
        <a className="footer-a" href="https://www.facebook.com/aocabo/">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={facebook}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
        <a className="footer-a" href="#">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={vimeo}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
        <a className="footer-a" href="mailto:aocaboteatro@gmail.com">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={email}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
      </div>
    </footer>
  );
}

export default FooterMain;
