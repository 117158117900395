import React from 'react';

function Loading(props) {
    return (
        <div>
            <p>Loading...</p>
        </div>
    );
}

export default Loading;