import React from "react";
import { Link } from "react-router-dom";
import { PODCASTSLIST } from "../../helper/Const";
import FooterSecondary from "../../footer/FooterSecondary";
import Navbar2 from "../../navbar/Navbar2";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

function Podcasts() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();
  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        podcasts
      </h1>
      <Container
        fluid
        style={{
          width: "85%",
          paddingTop: "15vh",
          marginBottom: "10vh",
        }}
      >
        <Row>
          {Object.entries(PODCASTSLIST).map((podcast, key) =>
            podcast[1].name !== "brevemente" ? (
              <Col>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/podcasts/" + podcast[0]}
                >
                  <div className="circle">
                    <div
                      style={{
                        textAlign: "center",
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "SlencoBold",
                          fontSize: "35px",
                          color: "black",
                          marginBottom: "-0.5rem",
                        }}
                      >
                        {podcast[1].name}
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
            ) : (
              <Col>
                <div className="circle_black">
                  <div
                    style={{
                      textAlign: "center",
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "SlencoBold",
                        fontSize: "45px",
                        color: "white",
                        marginBottom: "-0.5rem",
                      }}
                    >
                      {t("Audio.1")}
                    </p>
                  </div>
                </div>
              </Col>
            )
          )}
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Podcasts;
