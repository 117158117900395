import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

function Navbar() {
  const [audio_text, setAudio_text] = useState("");
  const [projeto_text, setProjeto_text] = useState("");
  const [teatro_text, setTeatro_text] = useState("");

  const [teste2, setTeste2] = useState(false);

  function test() {
    setTimeout(() => {
      setTeatro_text("");
    }, 900);
  }

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  return (
    <nav
      style={{
        textAlign: "right",
        position: "fixed",
        right: "10.5%",
        top: "3%",
      }}
    >
      <div className="navbar_animation"
      >
        <Link style={{ textDecoration: "none", color: "black" }} to="/audio">
          <p
            style={{
              margin: 0,
              fontFamily: "SlencoBold",
              fontSize: "30px",
              lineHeight: "0.60"
            }}
          >
            {audio_text}
            <span
              onMouseEnter={() => {
                setTimeout(() => {
                  setAudio_text("áudi");
                }, 900);
                controls.start({
                  x: -53,
                  scale: 0.8,
                  transition: {
                    duration: 1,
                  },
                });
              }}
              onMouseLeave={() => {
                setTimeout(() => {
                  setAudio_text("");
                }, 900);
                controls.start({
                  x: 0,
                  scale: 1,
                  transition: {
                    duration: 1,
                  },
                });
              }}
              style={{
                backgroundColor: "black",
                height: "1.7vh",
                width: "1.7vh",
                borderRadius: "50%",
                display: "inline-block",
                marginLeft: "1%",
              }}
            >
              <motion.span
                animate={controls}
                style={{
                  backgroundColor: "black",
                  height: "1.7vh",
                  width: "1.7vh",
                  borderRadius: "50%",
                  display: "block",
                }}
              ></motion.span>
            </span>
          </p>
        </Link>
        <Link style={{ textDecoration: "none", color: "black" }} to="/projetos">
          <motion.p
            style={{
              margin: 0,
              fontFamily: "SlencoBold",
              fontSize: "30px",
              lineHeight: "0.60"
            }}
          >
            {projeto_text}
            <motion.span
              onMouseEnter={() => {
                setTimeout(() => {
                  setProjeto_text("projet");
                }, 900);
                controls2.start({
                  x: -46,
                  transition: {
                    duration: 1,
                  },
                });
              }}
              onMouseLeave={() => {
                setTimeout(() => {
                  setProjeto_text("");
                }, 900);
                controls2.start({
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                });
              }}
              style={{
                backgroundColor: "black",
                height: "1.7vh",
                width: "1.7vh",
                borderRadius: "50%",
                display: "inline-block",
                marginLeft: "1%",
              }}
            >
              <motion.span
                animate={controls2}
                style={{
                  backgroundColor: "black",
                  height: "1.7vh",
                  width: "1.7vh",
                  borderRadius: "50%",
                  display: "block",
                }}
              ></motion.span>
            </motion.span>
            <span
              style={{
                position: "absolute",
                display: projeto_text === "projet" ? "inline-block" : "none",
              }}
            >
              s
            </span>
          </motion.p>
        </Link>
        <Link style={{ textDecoration: "none", color: "black" }} to="/sobre">
          <motion.p
            style={{
              margin: 0,
              fontFamily: "SlencoBold",
              fontSize: "30px",
              lineHeight: "0.60"
            }}
            transition={{
              duration: 1,
            }}
          >
            <motion.span
              onMouseLeave={() => {
                test();
                setTeste2(false);
                controls3.start({
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                });
              }}
              style={{
                width: "5px",
                height: "5px",
                fontFamily: "SlencoBold",
              }}
            >
              {teatro_text}
              <motion.span
                onMouseEnter={() => {
                  setTimeout(() => {
                    setTeatro_text("sobre");
                  }, 900);
                  setTeste2(true);
                  controls3.start({
                    x: -56,
                    transition: {
                      duration: 1,
                    },
                  });
                }}
                animate={controls3}
                style={{
                  backgroundColor: "black",
                  height: "1.7vh",
                  width: "1.7vh",
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></motion.span>
            </motion.span>
          </motion.p>
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
