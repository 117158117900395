import React, { useEffect, useState } from "react";
import FooterSecondary from "../../footer/FooterSecondary";
import Navbar2 from "../../navbar/Navbar2";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useTranslation } from "react-i18next";
import EquipaDetail from "./EquipaDetail";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

function Equipa() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();
  const [teamInfo, setTeamInfo] = useState([]);
  const [colaboradoresInfo, setColaboradoresInfo] = useState([]);

  useEffect(() => {
    async function EquipaDataCall() {
      const EquipaRef = collection(db, "Equipa");
      const q = query(EquipaRef, orderBy("Nome"));
      const querySnapshot = await getDocs(q);
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          name: doc.data().Nome,
          owner: doc.data().Owner,
          info_pt: doc.data().Informação.Informação_pt,
          info_en: doc.data().Informação.Informação_en,
          info_es: doc.data().Informação.Informação_es,
          link: doc.data().Link,
          imgUrl: doc.data().img,
        });
      });
      setTeamInfo(tempInfo);
    }
    EquipaDataCall();
    async function ColaboradoresDataCall() {
      const querySnapshot = await getDocs(collection(db, "Colaboradores"));
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          nomes: doc.data().Nomes,
        });
      });
      setColaboradoresInfo(tempInfo);
    }
    ColaboradoresDataCall();
  }, []);

  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        {t("Equipa.0")}
      </h1>
      <Container
        style={{
          paddingTop: "5vh",
          marginBottom: "10vh",
        }}
      >
        <Row
          className="justify-content-center"
          style={{ marginBottom: "5vh" }}
        >
          {teamInfo.map((item) => {
            return <EquipaDetail item={item} />;
          })}
        </Row>
        <h3
        className="colaboradores_title"
          style={{
            textAlign: "center",
            fontFamily: "SlencoBold",
            fontSize: "45px",
            marginBottom: "5vh",
            color: "black",
          }}
        >
          {t("Equipa.1")}
        </h3>
        <Row className="justify-content-center">
          {colaboradoresInfo.map((item) => {
            return (
              <Col lg={1} style={{ width: "80%" }}>
                <p
                  className="colaboradores"
                  dangerouslySetInnerHTML={{ __html: item.nomes }}
                  style={{
                    textAlign: "center",
                    fontFamily: "SlencoBold",
                    textJustify: "inter-word",
                    fontSize: "26px",
                    lineHeight: "30px",
                    color: "black",
                  }}
                ></p>
              </Col>
            );
          })}
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Equipa;
