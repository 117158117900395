import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import logoRed from "../../assets/AOCABOPONTO_logoRED.png"

function EpisodeItem(props) {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();

  let { podcast, episode } = useParams();

  const [titleDisplay, setTitleDisplay] = useState("block");
  const [dateDisplay, setDateDisplay] = useState("none");

  function hoverActionEnter() {
    setTitleDisplay("none");
    setDateDisplay("block");
  }

  function hoverActionLeave() {
    setTitleDisplay("block");
    setDateDisplay("none");
  }

  return (
    <Col>
      <div className="circle">
        <div
          style={{
            textAlign: "center",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          <Link
            onMouseEnter={() => hoverActionEnter()}
            onMouseLeave={() => hoverActionLeave()}
            style={{
              textDecoration: "none",
              color: "black",
              fontFamily: "SlencoBold",
              fontSize: "45px",
            }}
            to={"/podcasts/" + podcast + "/" + props.EpNumber}
          >
            <span id="title" style={{ display: titleDisplay }}>
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  borderRadius: "50%",
                }}
                // src={props.EpCover}
                src={logoRed}
              />
            </span>
            <span id="Date" style={{ display: dateDisplay }}>
              {props.episode?.title ? props.episode?.title : ""}
            </span>
          </Link>
        </div>
      </div>
      <p
        style={{
          textAlign: "center",
          color: "black",
          fontFamily: "SlencoBold",
          fontSize: "35px",
        }}
      >
        {t("Episodio.0")} {props.EpNumber + 1}
      </p>
    </Col>
  );
}

export default EpisodeItem;
