import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Sobre from "./sobre/Sobre";
import Historia from "./sobre/historia/Historia";
import Equipa from "./sobre/equipa/Equipa";
import Contactos from "./sobre/contactos/Contactos";
import Audio from "./audio/Audio";
import Projetos from "./projetos/Projetos";
import Projeto from "./projetos/projeto/Projeto";
import Podcasts from "./audio/podcasts/Podcasts";
import PodCastDetail from "./audio/podcasts/PodcastDetail";
import Episode from "./audio/podcasts/episodes/Episode";
import RssReader from "./assets/RssReader";
import AdminAuth from "./admin/Admin";
import AdminDashboard from "./admin/AdminDashboard";
import EquipaMembro from "./sobre/equipa/EquipaMembro";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./i18next";
import "./css/styles.css";

ReactDOM.render(
  <Suspense fallback={<></>}>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/audio" element={<Audio />} />
        <Route path="/podcasts" element={<Podcasts />} />
        <Route path="/podcasts/:podcast" element={<PodCastDetail />} />
        <Route path="/podcasts/:podcast/:episode" element={<Episode />} />
        <Route path="/projetos" element={<Projetos />} />
        <Route path="/projetos/:title" element={<Projeto />} />
        <Route path="/rss" element={<RssReader />} />
        <Route path="/historia" element={<Historia />} />
        <Route path="/equipa" element={<Equipa />} />
        <Route path="/equipa/:name" element={<EquipaMembro />} />
        <Route path="/contactos" element={<Contactos />} />
        <Route path="/admin" element={<AdminAuth />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
      </Routes>
    </Router>
  </Suspense>,
  document.getElementById("root")
);
