import React from "react";
import { Link } from "react-router-dom";
import FooterSecondary from "../footer/FooterSecondary";
import { useTranslation } from "react-i18next";
import Navbar2 from "../navbar/Navbar2";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

function Sobre() {
  const { t } = useTranslation();

  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        {t("Sobre.0")}
      </h1>
      <Container
        fluid
        style={{
          width: "85%",
          paddingTop: "15vh",
          marginBottom: "10vh",
        }}
      >
        <Row>
          <Col>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/historia"
            >
              <div className="circle">
                <div
                  style={{
                    textAlign: "center",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "SlencoBold",
                      fontSize: "45px",
                      marginBottom: "-0.5rem",
                    }}
                  >
                    {t("Sobre.1")}
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          <Col>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/equipa"
            >
              <div className="circle">
                <div
                  style={{
                    textAlign: "center",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "SlencoBold",
                      fontSize: "45px",
                      marginBottom: "-0.5rem",
                    }}
                  >
                    {t("Sobre.2")}
                  </p>
                </div>
              </div>
            </Link>
          </Col>
          <Col>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/contactos"
            >
              <div className="circle">
                <div
                  style={{
                    textAlign: "center",
                    display: "table-cell",
                    verticalAlign: "middle",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "SlencoBold",
                      fontSize: "45px",
                      marginBottom: "-0.5rem",
                    }}
                  >
                    {t("Sobre.3")}
                  </p>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Sobre;
