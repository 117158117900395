import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import FooterMain from "./footer/FooterMain";
import { motion, useAnimation } from "framer-motion";
import Navbar from "./navbar/Navbar";
import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import mobile_logo from "./assets/logo.png";
import mobile_audio from "./assets/audio.png";
import mobile_projetos from "./assets/projetos.png";
import mobile_sobre from "./assets/sobre.png";

function App() {
  const [audio_text, setAudio_text] = useState("a");
  const [projeto_text, setProjeto_text] = useState("cab");
  const [teatro_text, setTeatro_text] = useState("teatr");

  const [teste2, setTeste2] = useState(false);

  function test() {
    setTimeout(() => {
      setTeatro_text("teatr");
    }, 900);
  }

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Navbar />
      <Container fluid>
        <Row className="desktop_logo">
          <Col style={{ margin: "auto" }}>
            <div className="circle_logo">
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  textAlign: "right",
                  paddingRight: "70px",
                  fontSize: "130px",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/audio"
                >
                  <p
                    style={{
                      margin: 0,
                      lineHeight: "100px",
                      fontFamily: "SlencoBold",
                    }}
                  >
                    {audio_text}
                    <span
                      onMouseEnter={() => {
                        setTimeout(() => {
                          setAudio_text("áudi");
                        }, 900);
                        controls.start({
                          x: -231,
                          scale: 0.7,
                          transition: {
                            duration: 1,
                          },
                        });
                      }}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          setAudio_text("a");
                        }, 900);
                        controls.start({
                          x: 0,
                          scale: 1,
                          transition: {
                            duration: 1,
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "black",
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginLeft: "1%",
                      }}
                    >
                      <motion.span
                        animate={controls}
                        style={{
                          backgroundColor: "black",
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                          display: "block",
                          marginLeft: "1%",
                        }}
                      ></motion.span>
                    </span>
                  </p>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/projetos"
                >
                  <motion.p
                    style={{
                      margin: 0,
                      lineHeight: "100px",
                      fontFamily: "SlencoBold",
                    }}
                  >
                    {projeto_text}
                    <motion.span
                      onMouseEnter={() => {
                        setTimeout(() => {
                          setProjeto_text("projet");
                        }, 900);
                        controls2.start({
                          x: -205,
                          transition: {
                            duration: 1,
                          },
                        });
                      }}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          setProjeto_text("cab");
                        }, 900);
                        controls2.start({
                          x: 0,
                          transition: {
                            duration: 1,
                          },
                        });
                      }}
                      style={{
                        backgroundColor: "black",
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginLeft: "1%",
                      }}
                    >
                      <motion.span
                        animate={controls2}
                        style={{
                          backgroundColor: "black",
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                          display: "block",
                          marginLeft: "1%",
                        }}
                      ></motion.span>
                    </motion.span>
                    <span
                      style={{
                        position: "absolute",
                        display:
                          projeto_text === "projet" ? "inline-block" : "none",
                      }}
                    >
                      s
                    </span>
                  </motion.p>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/sobre"
                >
                  <motion.p
                    style={{
                      margin: 0,
                      lineHeight: "100px",
                      fontFamily: "SlencoBold",
                    }}
                    initial={{
                      x: 0,
                    }}
                    whileHover={{
                      x: teste2 === true ? 40 : 0,
                    }}
                    transition={{
                      duration: 1,
                    }}
                  >
                    <motion.span
                      onMouseLeave={() => {
                        test();
                        setTeste2(false);
                        controls3.start({
                          x: 0,
                          transition: {
                            duration: 1,
                          },
                        });
                      }}
                      style={{
                        width: "5px",
                        height: "5px",
                        fontFamily: "SlencoBold",
                      }}
                    >
                      {teatro_text}
                      <motion.span
                        onMouseEnter={() => {
                          setTimeout(() => {
                            setTeatro_text("sobre");
                          }, 900);
                          setTeste2(true);
                          controls3.start({
                            x: -250,
                            transition: {
                              duration: 1,
                            },
                          });
                        }}
                        animate={controls3}
                        style={{
                          backgroundColor: "black",
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                          display: "inline-block",
                          marginLeft: "1%",
                        }}
                      ></motion.span>
                    </motion.span>
                  </motion.p>
                </Link>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          className="mobile_logo justify-content-center"
          style={{
            display: "none",
          }}
        >
          <Col
            style={{
              textAlign: "center",
            }}
          >
            <img
              id="mainlogo"
              src={mobile_logo}
              style={{
                maxWidth: "300px",
                position: "absolute",
                top: "30vh",
                right: "12.5vw",
              }}
              onClick={() => {
                setIsOpen(!isOpen);
                document.getElementById("mainlogo").style.display = "none";
              }}
            />

            {isOpen !== true ? (
              <img
                src={mobile_projetos}
                style={{
                  maxWidth: "300px",
                  position: "absolute",
                  top: "40vh",
                  right: "12.5vw",
                  display: "none",
                }}
              />
            ) : (
              <Link to="/projetos">
                <img
                  src={mobile_projetos}
                  style={{
                    maxWidth: "300px",
                    position: "absolute",
                    top: "5vh",
                    right: "12.5vw",
                    animationName: "example1",
                    animationDuration: "1s",
                  }}
                />
              </Link>
            )}

            {isOpen !== true ? (
              <img
                src={mobile_audio}
                style={{
                  maxWidth: "300px",
                  position: "absolute",
                  top: "40vh",
                  right: "12.5vw",
                  display: "none",
                }}
              />
            ) : (
              <Link to="/audio">
                <img
                  src={mobile_audio}
                  style={{
                    maxWidth: "300px",
                    position: "absolute",
                    top: "40vh",
                    right: "12.5vw",
                    animationName: "example2",
                    animationDuration: "1s",
                  }}
                />
              </Link>
            )}

            {isOpen !== true ? (
              <img
                src={mobile_sobre}
                style={{
                  maxWidth: "300px",
                  position: "absolute",
                  top: "40vh",
                  right: "12.5vw",
                  display: "none",
                }}
              />
            ) : (
              <Link to="/sobre">
                <img
                  src={mobile_sobre}
                  style={{
                    maxWidth: "300px",
                    position: "absolute",
                    top: "75vh",
                    right: "12.5vw",
                    animationName: "example3",
                    animationDuration: "1s",
                    paddingBottom: "10vh"
                  }}
                />
              </Link>
            )}
          </Col>
        </Row>
      </Container>
      <FooterMain />
    </div>
  );
}

export default App;
