import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBwRZXqoGGdGfmuzG-4Vba1Ycpl8FxkIuI",
  authDomain: "ao-fim-e-ao-cabo.firebaseapp.com",
  projectId: "ao-fim-e-ao-cabo",
  storageBucket: "ao-fim-e-ao-cabo.appspot.com",
  messagingSenderId: "580815980064",
  appId: "1:580815980064:web:1bdb43730d31ebed8c5e45",
  measurementId: "G-6D9ELKNGBT",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth(app);
const storage = getStorage(app);

export { app, db, auth, storage };
