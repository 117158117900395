import React from "react";
import "./FooterCSS.css";
import instagram from "../assets/instagram.png";
import facebook from "../assets/facebook.png";
import vimeo from "../assets/vimeo.png";
import email from "../assets/email.png";
import logoDgartes from "../assets/logoDgartes.png";
import { Link } from "react-router-dom";
import logo_ao_cabo from "../assets/logo.png";
import { motion } from "framer-motion";

function Footer() {
  return (
    <footer className="footer-basic">
      <div className="social">
        <Link to="/">
          <img
          className="aocaboteatrologo"
            style={{
              height: "6vh",
              width: "auto",
              position: "fixed",
              left: "12%",
              bottom: "1%",
            }}
            src={logo_ao_cabo}
          ></img>
        </Link>
        <a className="footer-a" href="https://www.instagram.com/aocaboteatro/">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={instagram}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
        <a className="footer-a" href="https://www.facebook.com/aocabo/">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={facebook}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
        <a className="footer-a" href="#">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={vimeo}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
        <a className="footer-a" href="mailto:aocaboteatro@gmail.com">
          <motion.img
            whileHover={{
              scale: 1.1,
            }}
            whileTap={{ scale: 0.9 }}
            src={email}
            style={{ height: "6vh", width: "auto" }}
          />
        </a>
        <img
          className="logoDgartes"
          style={{
            height: "7vh",
            position: "fixed",
            right: "12%",
            bottom: "0%",
          }}
          src={logoDgartes}
        ></img>
      </div>
    </footer>
  );
}

export default Footer;
