import React from "react";
import FooterSecondary from "../../footer/FooterSecondary";
import Navbar2 from "../../navbar/Navbar2";
import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

function Contactos() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();

  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        {t("Contacto.0")}
      </h1>
      <Container
        style={{
          paddingTop: "30vh",
        }}
      >
        <Row>
          <p
            className="contactos"
            style={{
              fontFamily: "SlencoBold",
              fontSize: "60px",
              color: "black",
              marginBottom: "15%",
              textAlign: "center",
            }}
          >
            aocaboteatro@gmail.com
          </p>
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Contactos;
