import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterSecondary from "../footer/FooterSecondary";
import Navbar2 from "../navbar/Navbar2";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ProjetosItem from "./ProjetosItem";

function Projetos() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();
  const [projectsInfo, setProjectsInfo] = useState([]);

  useEffect(() => {
    async function ProjetosDataCall() {
      const ProjetosRef = collection(db, "Projetos");
      const q = query(ProjetosRef, orderBy("Date", "desc"));
      const querySnapshot = await getDocs(q);
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          title: doc.data().Title,
          capa: doc.data().Capa,
          desc_pt: doc.data().Description?.Description_pt,
          desc_en: doc.data().Description?.Description_en,
          desc_es: doc.data().Description?.Description_es,
          ficha_pt: doc.data().Ficha?.Ficha_pt,
          ficha_en: doc.data().Ficha?.Ficha_en,
          ficha_es: doc.data().Ficha?.Ficha_es,
          imagens: doc.data().Imagens,
          date: doc.data().Date,
        });
      });
      setProjectsInfo(tempInfo);
    }
    ProjetosDataCall();
  }, []);

  async function filterYear(year) {
    const ProjetosRef = collection(db, "Projetos");
    const q2 = query(ProjetosRef, where("Date", "==", year));
    const querySnapshot = await getDocs(q2);
    let tempInfo = [];
    querySnapshot.forEach((doc) => {
      tempInfo.push({
        id: doc.id,
        title: doc.data().Title,
        capa: doc.data().Capa,
        desc_pt: doc.data().Description?.Description_pt,
        desc_en: doc.data().Description?.Description_en,
        desc_es: doc.data().Description?.Description_es,
        ficha_pt: doc.data().Ficha?.Ficha_pt,
        ficha_en: doc.data().Ficha?.Ficha_en,
        ficha_es: doc.data().Ficha?.Ficha_es,
        imagens: doc.data().Imagens,
        date: doc.data().Date,
      });
    });
    setProjectsInfo(tempInfo);
    console.log(projectsInfo, "done");
  }

  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        {t("Projetos.0")}
      </h1>
      <Container
        style={{
          paddingTop: "3vh",
          marginBottom: "10vh",
          width: "80%",
        }}
      >
        <Row
          style={{
            marginBottom: "2vh",
            fontFamily: "SlencoBlack",
            textAlign: "center",
          }}
        >
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2001);
            }}
          >
            2001
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2002);
            }}
          >
            2002
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2003);
            }}
          >
            2003
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2009);
            }}
          >
            2009
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2010);
            }}
          >
            2010
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2011);
            }}
          >
            2011
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2012);
            }}
          >
            2012
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2013);
            }}
          >
            2013
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2014);
            }}
          >
            2014
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2015);
            }}
          >
            2015
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2016);
            }}
          >
            2016
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2017);
            }}
          >
            2017
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2018);
            }}
          >
            2018
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2019);
            }}
          >
            2019
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2020);
            }}
          >
            2020
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2021);
            }}
          >
            2021
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              filterYear(2022);
            }}
          >
            2022
          </Col>
        </Row>
        <Row lg="3">
          {projectsInfo.map((project) => (
            <ProjetosItem project={project} />
          ))}
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Projetos;
