import React, { useState, useEffect } from "react";
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { app, db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Modal from "react-modal";
import { Editor } from "@tinymce/tinymce-react";
import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";
import { v4 as uuidv4 } from "uuid";

Modal.setAppElement("#root");

function AdminDashboard() {
  const [projectsInfo, setProjectsInfo] = useState([]);
  const [modalInfo, setModalInfo] = useState({});
  const [teamInfo, setTeamInfo] = useState([]);
  const [colaboradoresInfo, setColaboradoresInfo] = useState([]);
  const [modalIsOpenNewTeam, setModalIsOpenNewTeam] = useState(false);
  const [modalIsOpenNewProjects, setModalIsOpenNewProjects] = useState(false);

  const [inputs, setInputs] = useState({});

  const [modalIsOpenProjects, setModalIsOpenProjects] = useState(false);
  const [modalIsOpenTeam, setModalIsOpenTeam] = useState(false);
  const [modalIsOpenColaboradores, setModalIsOpenColaboradores] =
    useState(false);
  const [modalIsOpenEditProjects, setModalIsOpenEditProjects] = useState(false);
  const [modalIsOpenEditTeam, setModalIsOpenEditTeam] = useState(false);
  const [modalIsOpenEditColaboradores, setModalIsOpenEditColaboradores] =
    useState(false);

  const navigate = useNavigate();
  const [user, setUser] = useState(localStorage.getItem("user"));
  const auth = getAuth();
  let returnContent;
  onAuthStateChanged(auth, (loggedUser) => {
    if (loggedUser) {
      setUser(loggedUser);
    } else {
    }
  });

  const logout = async () => {
    await signOut(auth);
    localStorage.removeItem("user");
    navigate("/");
  };

  function reset() {
    window.location.reload(false);
  }

  async function ProjetosDelete(modalInfo) {
    await deleteDoc(doc(db, "Projetos", modalInfo.id));
    closeModal();
    alert("Foi removido um Projeto");
    reset();
  }

  async function EquipaDelete(modalInfo) {
    await deleteDoc(doc(db, "Equipa", modalInfo.id));
    closeModal();
    alert("Foi removido um membro da Equipa");
    reset();
  }

  async function ColaboradoresDelete(modalInfo) {
    await deleteDoc(doc(db, "Colaboradores", modalInfo.id));
    closeModal();
    alert("Foi removido um membro dos Colaboradores");
    reset();
  }

  async function NewProjetosDataSet(inputs) {
    const docRef = await addDoc(collection(db, "Projetos"), {
      Title:
        inputs?.title !== undefined && inputs.title !== ""
          ? inputs.title
          : "Título",
      Capa:
        inputs?.capa_url !== undefined && inputs.capa_url !== ""
          ? inputs.capa_url
          : "https://firebasestorage.googleapis.com/v0/b/ao-fim-e-ao-cabo.appspot.com/o/projetos%2Flogo.png?alt=media&token=cb0923a6-abb9-490b-bea2-7272dac1a6e1",
      Description: {
        Description_pt:
          inputs?.desc_pt !== undefined && inputs.desc_pt !== ""
            ? inputs.desc_pt
            : "Descrição_pt",
        Description_en:
          inputs?.desc_en !== undefined && inputs.desc_en !== ""
            ? inputs.desc_en
            : "Descrição_en",
        Description_es:
          inputs?.desc_es !== undefined && inputs.desc_es !== ""
            ? inputs.desc_es
            : "Descrição_es",
      },
      Ficha: {
        Ficha_pt:
          inputs?.ficha_pt !== undefined && inputs.ficha_pt !== ""
            ? inputs.ficha_pt
            : "Ficha Técnica_pt",
        Ficha_en:
          inputs?.ficha_en !== undefined && inputs.ficha_en !== ""
            ? inputs.ficha_en
            : "Ficha Técnica_en",
        Ficha_es:
          inputs?.ficha_es !== undefined && inputs.ficha_es !== ""
            ? inputs.ficha_es
            : "Ficha Técnica_es",
      },
      Imagens: {
        img1:
          inputs?.imagens1 !== undefined && inputs.imagens1 !== ""
            ? inputs.imagens1
            : "",
        img2:
          inputs?.imagens2 !== undefined && inputs.imagens2 !== ""
            ? inputs.imagens2
            : "",
        img3:
          inputs?.imagens3 !== undefined && inputs.imagens3 !== ""
            ? inputs.imagens3
            : "",
        img4:
          inputs?.imagens4 !== undefined && inputs.imagens4 !== ""
            ? inputs.imagens4
            : "",
        img5:
          inputs?.imagens5 !== undefined && inputs.imagens5 !== ""
            ? inputs.imagens5
            : "",
        img6:
          inputs?.imagens6 !== undefined && inputs.imagens6 !== ""
            ? inputs.imagens6
            : "",
      },
      Date:
        inputs?.date !== undefined && inputs.date !== "" ? inputs.date : "2022",
    });
    closeModal();
    alert("Foi adicionado um novo Projeto");
    reset();
  }

  async function NewEquipaDataSet(inputs) {
    const docRef = await addDoc(collection(db, "Equipa"), {
      Nome:
        inputs?.name !== undefined && inputs.name !== "" ? inputs.name : "Nome",
      Cargos:
        inputs?.cargos !== undefined && inputs.cargos !== ""
          ? inputs.cargos
          : "Cargos",
      Posição:
        inputs?.posição !== undefined && inputs.posição !== ""
          ? inputs.posição
          : "Posição",
      Informação: {
        Informação_pt:
          inputs?.info_pt !== undefined && inputs.info_pt !== ""
            ? inputs.info_pt
            : "Informação PT",
        Informação_en:
          inputs?.info_en !== undefined && inputs.info_en !== ""
            ? inputs.info_en
            : "Informação EN",
        Informação_es:
          inputs?.info_es !== undefined && inputs.info_es !== ""
            ? inputs.info_es
            : "Informação ES",
      },
      Link:
        inputs?.link !== undefined && inputs.link !== "" ? inputs.link : "Link",
      img:
        inputs?.img_url !== undefined && inputs.img_url !== ""
          ? inputs.img_url
          : "imgUrl",
    });
    closeModal();
    alert("Foi adicionado um novo membro na Equipa");
    reset();
  }

  // async function NewColaboradoresDataSet(inputs) {
  //   const docRef = await addDoc(collection(db, "Colaboradores"), {
  //     Nomes:
  //       inputs?.nomes !== undefined && inputs.nomes !== "" ? inputs.nomes : "Colaboradores",
  //   });
  //   closeModal();
  //   alert("Foi adicionado um novo Colaborador na Equipa");
  //   reset();
  // }

  async function ProjetosDataSet(inputs, modalInfo) {
    await setDoc(doc(db, "Projetos", modalInfo.id), {
      Title:
        inputs?.title !== undefined && inputs.title !== ""
          ? inputs.title
          : modalInfo.title,
      Capa:
        inputs?.capa_url !== undefined && inputs.capa_url !== ""
          ? inputs.capa_url
          : modalInfo.capa_url,
      Description: {
        Description_pt:
          inputs?.desc_pt !== undefined && inputs.desc_pt !== ""
            ? inputs.desc_pt
            : modalInfo.desc_pt,
        Description_en:
          inputs?.desc_en !== undefined && inputs.desc_en !== ""
            ? inputs.desc_en
            : modalInfo.desc_en,
        Description_es:
          inputs?.desc_es !== undefined && inputs.desc_es !== ""
            ? inputs.desc_es
            : modalInfo.desc_es,
      },
      Ficha: {
        Ficha_pt:
          inputs?.ficha_pt !== undefined && inputs.ficha_pt !== ""
            ? inputs.ficha_pt
            : modalInfo.ficha_pt,
        Ficha_en:
          inputs?.ficha_en !== undefined && inputs.ficha_en !== ""
            ? inputs.ficha_en
            : modalInfo.ficha_en,
        Ficha_es:
          inputs?.ficha_es !== undefined && inputs.ficha_es !== ""
            ? inputs.ficha_es
            : modalInfo.ficha_es,
      },
      Imagens: {
        img1:
          inputs?.imagens1 !== undefined && inputs.imagens1 !== ""
            ? inputs.imagens1
            : modalInfo.imagens1,
        img2:
          inputs?.imagens2 !== undefined && inputs.imagens2 !== ""
            ? inputs.imagens2
            : modalInfo.imagens2,
        img3:
          inputs?.imagens3 !== undefined && inputs.imagens3 !== ""
            ? inputs.imagens3
            : modalInfo.imagens3,
        img4:
          inputs?.imagens4 !== undefined && inputs.imagens4 !== ""
            ? inputs.imagens4
            : modalInfo.imagens4,
        img5:
          inputs?.imagens5 !== undefined && inputs.imagens5 !== ""
            ? inputs.imagens5
            : modalInfo.imagens5,
        img6:
          inputs?.imagens6 !== undefined && inputs.imagens6 !== ""
            ? inputs.imagens6
            : modalInfo.imagens6,
      },
      Date:
        inputs?.date !== undefined && inputs.date !== ""
          ? inputs.date
          : modalInfo.date,
    });
    closeModal();
    alert("Os dados do projeto foram alterados");
    reset();
  }

  async function EquipaDataSet(inputs, modalInfo) {
    await setDoc(doc(db, "Equipa", modalInfo.id), {
      Nome:
        inputs?.name !== undefined && inputs.name !== ""
          ? inputs.name
          : modalInfo.name,
      Cargos:
        inputs?.cargos !== undefined && inputs.cargos !== ""
          ? inputs.cargos
          : modalInfo.cargos,
      Posição:
        inputs?.posição !== undefined && inputs.posição !== ""
          ? inputs.posição
          : modalInfo.posição,
      Informação: {
        Informação_pt:
          inputs?.info_pt !== undefined && inputs.info_pt !== ""
            ? inputs.info_pt
            : modalInfo.info_pt,
        Informação_en:
          inputs?.info_en !== undefined && inputs.info_en !== ""
            ? inputs.info_en
            : modalInfo.info_en,
        Informação_es:
          inputs?.info_es !== undefined && inputs.info_es !== ""
            ? inputs.info_es
            : modalInfo.info_es,
      },
      Link:
        inputs?.link !== undefined && inputs.link !== ""
          ? inputs.link
          : modalInfo.link,
      img:
        inputs?.img_url !== undefined && inputs.img_url !== ""
          ? inputs.img_url
          : modalInfo.img_url,
    });
    closeModal();
    alert("Os dados do membro da Equipa foram alterados");
    reset();
  }

  async function ColaboradoresDataSet(inputs, modalInfo) {
    await setDoc(doc(db, "Colaboradores", modalInfo.id), {
      Nomes:
        inputs?.nomes !== undefined && inputs.nomes !== ""
          ? inputs.nomes
          : modalInfo.nomes,
    });
    closeModal();
    alert("Os dados dos Colaboradores foram alterados");
    reset();
  }

  useEffect(() => {
    async function ProjetosDataCall() {
      const querySnapshot = await getDocs(collection(db, "Projetos"));
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          title: doc.data().Title,
          capa: doc.data().Capa,
          desc_pt: doc.data().Description?.Description_pt,
          desc_en: doc.data().Description?.Description_en,
          desc_es: doc.data().Description?.Description_es,
          ficha_pt: doc.data().Ficha?.Ficha_pt,
          ficha_en: doc.data().Ficha?.Ficha_en,
          ficha_es: doc.data().Ficha?.Ficha_es,
          imagens1: doc.data().Imagens.img1,
          imagens2: doc.data().Imagens.img2,
          imagens3: doc.data().Imagens.img3,
          imagens4: doc.data().Imagens.img4,
          imagens5: doc.data().Imagens.img5,
          imagens6: doc.data().Imagens.img6,
          date: doc.data().Date,
        });
      });
      setProjectsInfo(tempInfo);
    }
    ProjetosDataCall();
    async function EquipaDataCall() {
      const querySnapshot = await getDocs(collection(db, "Equipa"));
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          name: doc.data().Nome,
          cargos: doc.data().Cargos,
          posição: doc.data().Posição,
          info_pt: doc.data().Informação.Informação_pt,
          info_en: doc.data().Informação.Informação_en,
          info_es: doc.data().Informação.Informação_es,
          link: doc.data().Link,
          imgUrl: doc.data().img,
        });
      });
      setTeamInfo(tempInfo);
    }
    EquipaDataCall();
    async function ColaboradoresDataCall() {
      const querySnapshot = await getDocs(collection(db, "Colaboradores"));
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          nomes: doc.data().Nomes,
        });
      });
      setColaboradoresInfo(tempInfo);
    }
    ColaboradoresDataCall();
  }, []);

  function openModalProjects(item) {
    setModalInfo(item);
    setModalIsOpenProjects(true);
  }

  function openModalTeam(item) {
    setModalInfo(item);
    setModalIsOpenTeam(true);
  }

  function openModalColaboradores(item) {
    setModalInfo(item);
    setModalIsOpenColaboradores(true);
  }

  function closeModal() {
    setModalIsOpenProjects(false);
    setModalIsOpenTeam(false);
    setModalIsOpenColaboradores(false);
    setModalIsOpenEditProjects(false);
    setModalIsOpenEditTeam(false);
    setModalIsOpenEditColaboradores(false);
    setModalIsOpenNewProjects(false);
    setModalIsOpenNewTeam(false);
    // setModalIsOpenNewColaboradores(false);
  }

  function openEditFormProjects(modalInfo) {
    setModalIsOpenProjects(false);
    setModalIsOpenTeam(false);
    setModalIsOpenEditProjects(true);
    setModalInfo(modalInfo);
    setInputs("");
  }

  function openEditFormTeam(modalInfo) {
    setModalIsOpenProjects(false);
    setModalIsOpenTeam(false);
    setModalIsOpenEditTeam(true);
    setModalInfo(modalInfo);
    setInputs("");
  }

  function openEditFormColaboradores(modalInfo) {
    setModalIsOpenProjects(false);
    setModalIsOpenTeam(false);
    setModalIsOpenEditColaboradores(true);
    setModalInfo(modalInfo);
    setInputs("");
  }

  function openNewFormTeam() {
    setModalIsOpenProjects(false);
    setModalIsOpenTeam(false);
    setModalIsOpenNewTeam(true);
    setInputs("");
  }

  function openNewFormProjects() {
    setModalIsOpenProjects(false);
    setModalIsOpenTeam(false);
    setModalIsOpenNewProjects(true);
    setInputs("");
  }

  // function openNewFormColaboradores() {
  //   setModalIsOpenProjects(false);
  //   setModalIsOpenTeam(false);
  //   setModalIsOpenNewColaboradores(true);
  //   setInputs("");
  // }

  const fileHandleChangeCapa = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `projetos/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["capa_url"]: downloadURL }));
      });
    });
  };

  const fileHandleChangeProfile = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `membros/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["img_url"]: downloadURL }));
      });
    });
  };

  const fileHandleChange1 = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `projetos/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["imagens1"]: downloadURL }));
      });
    });
  };

  const fileHandleChange2 = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `projetos/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["imagens2"]: downloadURL }));
      });
    });
  };

  const fileHandleChange3 = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `projetos/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["imagens3"]: downloadURL }));
      });
    });
  };

  const fileHandleChange4 = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `projetos/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["imagens4"]: downloadURL }));
      });
    });
  };

  const fileHandleChange5 = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `projetos/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["imagens5"]: downloadURL }));
      });
    });
  };

  const fileHandleChange6 = (event) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `projetos/${uuidv4()}`);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a blob or file!");
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        setInputs((values) => ({ ...values, ["imagens6"]: downloadURL }));
      });
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginLeft: "5%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      height: "80%",
      fontSize: "15px",
    },
  };

  if (user !== null) {
    returnContent = (
      <div>
        <section
          className="w3-sidebar w3-gray w3-bar-block"
          style={{ width: "10%" }}
        >
          <h3 className="w3-bar-item">Menu</h3>
          <a href="#projetos" className="w3-bar-item w3-button">
            Projetos
          </a>
          <a href="#equipa" className="w3-bar-item w3-button">
            Equipa
          </a>
          <a href="#colaboradores" className="w3-bar-item w3-button">
            Colaboradores
          </a>
        </section>
        <div style={{ marginLeft: "10%" }}>
          <div
            className="w3-container w3-blue-gray"
            style={{ position: "fixed", width: "100%" }}
          >
            <h3 style={{ display: "inline-block" }}>Welcome {user.email}</h3>
            <button
              onClick={logout}
              style={{
                display: "inline-block",
                position: "fixed",
                right: "1%",
                top: "1.5%",
              }}
            >
              Leave
            </button>
          </div>

          <div
            id="projetos"
            className="w3-container"
            style={{ marginBottom: "50vh", paddingTop: "5%" }}
          >
            <div style={{ display: "flex" }}>
              <h2>Projetos</h2>
              <button
                onClick={() => openNewFormProjects()}
                style={{
                  height: "3vh",
                }}
              >
                Novo Projeto
              </button>
            </div>

            <Modal
              isOpen={modalIsOpenNewProjects}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>

                <form onSubmit={handleSubmit}>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Título:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="title"
                      value={inputs.title || ""}
                      onChange={handleChange}
                      placeholder="Título"
                    ></input>
                  </label>
                  <label
                    style={{
                      marginTop: "1vh",
                      marginLeft: "1vw",
                    }}
                  >
                    Imagem Capa:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="file"
                      name="capa"
                      value={inputs.capa || ""}
                      onChange={fileHandleChangeCapa}
                      accept="image/png, image/jpeg"
                    />
                    <input
                      style={{
                        display: "none",
                      }}
                      type="text"
                      name="capa_url"
                      value={inputs.capa_url || ""}
                      onChange={handleChange}
                    ></input>
                  </label>
                  <div>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Descrição PT:
                      <Editor
                        initialValue="Descrição PT"
                        type="text"
                        name="desc_pt"
                        value={inputs.desc_pt}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["desc_pt"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Descrição EN:
                      <Editor
                        initialValue="Descrição EN"
                        type="text"
                        name="desc_en"
                        value={inputs.desc_en}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["desc_en"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Descrição ES:
                      <Editor
                        initialValue="Descrição ES"
                        type="text"
                        name="desc_es"
                        value={inputs.desc_es}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["desc_es"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Ficha Técnica PT:
                      <Editor
                        initialValue="Ficha Técnica PT"
                        type="text"
                        name="ficha_pt"
                        value={inputs.ficha_pt}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["ficha_pt"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Ficha Técnica EN:
                      <Editor
                        initialValue="Ficha Técnica EN"
                        type="text"
                        name="ficha_en"
                        value={inputs.ficha_en}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["ficha_en"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Ficha Técnica ES:
                      <Editor
                        initialValue="Ficha Técnica ES"
                        type="text"
                        name="ficha_es"
                        value={inputs.ficha_es}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["ficha_es"]: newValue,
                          }));
                        }}
                      />
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 1:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img1"
                        value={inputs.img1 || ""}
                        onChange={fileHandleChange1}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens1"
                        value={inputs.imagens1 || ""}
                        onChange={handleChange}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 2:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img2"
                        value={inputs.img2 || ""}
                        onChange={fileHandleChange2}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens2"
                        value={inputs.imagens2 || ""}
                        onChange={handleChange}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 3:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img3"
                        value={inputs.img3 || ""}
                        onChange={fileHandleChange3}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens3"
                        value={inputs.imagens3 || ""}
                        onChange={handleChange}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 4:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img4"
                        value={inputs.img4 || ""}
                        onChange={fileHandleChange4}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens4"
                        value={inputs.imagens4 || ""}
                        onChange={handleChange}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 5:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img5"
                        value={inputs.img5 || ""}
                        onChange={fileHandleChange5}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens5"
                        value={inputs.imagens5 || ""}
                        onChange={handleChange}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 6:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img6"
                        value={inputs.img6 || ""}
                        onChange={fileHandleChange6}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens6"
                        value={inputs.imagens6 || ""}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Data(Ano):
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="number"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                    ></input>
                  </label>
                  <br></br>
                  <button
                    style={{
                      marginTop: "1vh",
                    }}
                    type="submit"
                    onClick={() => NewProjetosDataSet(inputs)}
                  >
                    Confirm
                  </button>
                </form>
              </Container>
            </Modal>

            {projectsInfo.map((item) => (
              <ul>
                <li onClick={() => openModalProjects(item)}>{item.title}</li>
              </ul>
            ))}

            <Modal
              isOpen={modalIsOpenProjects}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>
                <div
                  style={{
                    marginTop: "1vh",
                  }}
                >
                  <h3>Título: {modalInfo.title}</h3>
                  <h3>
                    Descrição:
                    <div
                      style={{
                        marginTop: "1vh",
                        height: "20vh",
                        width: "60vw",
                        overflowY: "auto",
                        border: "solid 1px grey",
                      }}
                      dangerouslySetInnerHTML={{ __html: modalInfo.desc_pt }}
                    ></div>
                  </h3>
                  <h3>
                    Ficha Técnica:
                    <div
                      style={{
                        marginTop: "1vh",
                        height: "20vh",
                        width: "60vw",
                        overflowY: "auto",
                        border: "solid 1px grey",
                      }}
                      dangerouslySetInnerHTML={{ __html: modalInfo.ficha_pt }}
                    ></div>
                  </h3>
                  <h3>Data(Ano): {modalInfo.date}</h3>
                  <h3>
                    Imagem Capa:
                    <img
                      style={{
                        width: "150px",
                        height: "auto",
                        marginLeft: "1vw",
                      }}
                      src={modalInfo.capa}
                    ></img>
                  </h3>
                </div>
                <button onClick={() => openEditFormProjects(modalInfo)}>
                  Editar
                </button>
                <button onClick={() => ProjetosDelete(modalInfo)}>
                  Remover
                </button>
              </Container>
            </Modal>

            <Modal
              isOpen={modalIsOpenEditProjects}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>

                <form onSubmit={handleSubmit}>
                  <div>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Título:
                      <input
                        style={{
                          marginTop: "1vh",
                          marginLeft: "1vw",
                        }}
                        type="text"
                        name="title"
                        value={inputs.title || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.title}
                      ></input>
                    </label>
                    <label
                      style={{
                        marginLeft: "1vw",
                      }}
                    >
                      Imagem Capa:
                      <input
                        style={{
                          marginTop: "1vh",
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="capa"
                        value={inputs.capa || ""}
                        onChange={fileHandleChangeCapa}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="capa_url"
                        value={inputs.capa_url || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.capa_url}
                      ></input>
                    </label>
                  </div>
                  <div>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Descrição PT:
                      <Editor
                        initialValue={modalInfo.desc_pt}
                        type="text"
                        name="desc_pt"
                        value={inputs.desc_pt}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["desc_pt"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Descrição EN:
                      <Editor
                        initialValue={modalInfo.desc_en}
                        type="text"
                        name="desc_en"
                        value={inputs.desc_en}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["desc_en"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Descrição ES:
                      <Editor
                        initialValue={modalInfo.desc_es}
                        type="text"
                        name="desc_es"
                        value={inputs.desc_es}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["desc_es"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Ficha Técnica PT:
                      <Editor
                        initialValue={modalInfo.ficha_pt}
                        type="text"
                        name="ficha_pt"
                        value={inputs.ficha_pt}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["ficha_pt"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Ficha Técnica EN:
                      <Editor
                        initialValue={modalInfo.ficha_en}
                        type="text"
                        name="ficha_en"
                        value={inputs.ficha_en}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["ficha_en"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Ficha Técnica ES:
                      <Editor
                        initialValue={modalInfo.ficha_es}
                        type="text"
                        name="ficha_es"
                        value={inputs.ficha_es}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["ficha_es"]: newValue,
                          }));
                        }}
                      />
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 1:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img1"
                        value={inputs.img1 || ""}
                        onChange={fileHandleChange1}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens1"
                        value={inputs.imagens1 || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.imagens1}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 2:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img2"
                        value={inputs.img2 || ""}
                        onChange={fileHandleChange2}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens2"
                        value={inputs.imagens2 || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.imagens2}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 3:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img3"
                        value={inputs.img3 || ""}
                        onChange={fileHandleChange3}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens3"
                        value={inputs.imagens3 || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.imagens3}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 4:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img4"
                        value={inputs.img4 || ""}
                        onChange={fileHandleChange4}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens4"
                        value={inputs.imagens4 || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.imagens4}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 5:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img5"
                        value={inputs.img5 || ""}
                        onChange={fileHandleChange5}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens5"
                        value={inputs.imagens5 || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.imagens5}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Imagem 6:
                      <input
                        style={{
                          marginLeft: "1vw",
                        }}
                        type="file"
                        name="img6"
                        value={inputs.img6 || ""}
                        onChange={fileHandleChange6}
                      />
                      <input
                        style={{
                          display: "none",
                        }}
                        type="text"
                        name="imagens6"
                        value={inputs.imagens6 || ""}
                        onChange={handleChange}
                        placeholder={modalInfo.imagens6}
                      />
                    </label>
                  </div>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Data(Ano):
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="number"
                      name="date"
                      value={inputs.date || ""}
                      onChange={handleChange}
                      placeholder={modalInfo.date}
                    ></input>
                  </label>
                  <br></br>
                  <button
                    style={{
                      marginTop: "1vh",
                    }}
                    type="submit"
                    onClick={() => ProjetosDataSet(inputs, modalInfo)}
                  >
                    Confirm
                  </button>
                </form>
              </Container>
            </Modal>
          </div>

          <div
            id="equipa"
            className="w3-container"
            style={{ marginBottom: "50vh", paddingTop: "5%" }}
          >
            <div style={{ display: "flex" }}>
              <h2>Equipa Principal</h2>
              <button
                onClick={() => openNewFormTeam()}
                style={{
                  height: "3vh",
                }}
              >
                Novo Membro
              </button>
            </div>

            <Modal
              isOpen={modalIsOpenNewTeam}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>

                <form onSubmit={handleSubmit}>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Nome:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder="Nome"
                    ></input>
                  </label>
                  <label
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    Posição:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="posição"
                      value={inputs.posição || ""}
                      onChange={handleChange}
                      placeholder="Posição"
                    ></input>
                  </label>
                  <label
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    Cargos:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="cargos"
                      value={inputs.cargos || ""}
                      onChange={handleChange}
                      placeholder="Cargos"
                    ></input>
                  </label>
                  <div>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Informação PT:
                      <Editor
                        initialValue="Informação PT"
                        type="text"
                        name="info_pt"
                        value={inputs.info_pt}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["info_pt"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Informação EN:
                      <Editor
                        initialValue="Informação EN"
                        type="text"
                        name="info_en"
                        value={inputs.info_en}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["info_en"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Informação ES:
                      <Editor
                        initialValue="Informação ES"
                        type="text"
                        name="info_es"
                        value={inputs.info_es}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["info_es"]: newValue,
                          }));
                        }}
                      />
                    </label>
                  </div>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Link:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="link"
                      value={inputs.link || ""}
                      onChange={handleChange}
                      placeholder="Link"
                    ></input>
                  </label>
                  <label
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    Image:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="file"
                      name="img"
                      value={inputs.img || ""}
                      onChange={fileHandleChangeProfile}
                    />
                    <input
                      style={{
                        display: "none",
                      }}
                      type="text"
                      name="img_url"
                      value={inputs.img_url || ""}
                      onChange={handleChange}
                    />
                  </label>
                  <br></br>
                  <button
                    style={{
                      marginTop: "1vh",
                    }}
                    type="submit"
                    onClick={() => NewEquipaDataSet(inputs)}
                  >
                    Confirm
                  </button>
                </form>
              </Container>
            </Modal>

            {teamInfo.map((item) => (
              <ul>
                <li onClick={() => openModalTeam(item)}>{item.name}</li>
              </ul>
            ))}

            <Modal
              isOpen={modalIsOpenTeam}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>
                <div>
                  <h3>Nome: {modalInfo.name}</h3>
                  <h3>Posição: {modalInfo.posição}</h3>
                  <h3>Cargos: {modalInfo.cargos}</h3>
                  <h3>
                    Informação:
                    <div
                      style={{
                        height: "40vh",
                        width: "60vw",
                        overflowY: "auto",
                        border: "solid 1px grey",
                      }}
                      dangerouslySetInnerHTML={{ __html: modalInfo.info_pt }}
                    ></div>
                  </h3>
                  <h3>Link: {modalInfo.link}</h3>
                  <h3>
                    img:
                    <img
                      style={{ height: "15vh", width: "auto" }}
                      src={modalInfo.imgUrl}
                    ></img>
                  </h3>
                </div>
                <button onClick={() => openEditFormTeam(modalInfo)}>
                  Editar
                </button>
                <button onClick={() => EquipaDelete(modalInfo)}>Remover</button>
              </Container>
            </Modal>

            <Modal
              isOpen={modalIsOpenEditTeam}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>

                <form onSubmit={handleSubmit}>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Nome:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="name"
                      value={inputs.name || ""}
                      onChange={handleChange}
                      placeholder={modalInfo.name}
                    ></input>
                  </label>
                  <label
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    Posição:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="posição"
                      value={inputs.posição || ""}
                      onChange={handleChange}
                      placeholder={modalInfo.posição}
                    ></input>
                  </label>
                  <label
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    Cargos:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="cargos"
                      value={inputs.cargos || ""}
                      onChange={handleChange}
                      placeholder={modalInfo.cargos}
                    ></input>
                  </label>
                  <div>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Informação PT:
                      <Editor
                        initialValue={modalInfo.info_pt}
                        type="text"
                        name="info_pt"
                        value={inputs.info_pt}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["info_pt"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Informação EN:
                      <Editor
                        initialValue={modalInfo.info_en}
                        type="text"
                        name="info_en"
                        value={inputs.info_en}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["info_en"]: newValue,
                          }));
                        }}
                      />
                    </label>
                    <label
                      style={{
                        marginTop: "1vh",
                      }}
                    >
                      Informação ES:
                      <Editor
                        initialValue={modalInfo.info_es}
                        type="text"
                        name="info_es"
                        value={inputs.info_es}
                        apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                        init={{
                          height: 200,
                          menubar: true,
                          plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                        }}
                        onEditorChange={(newValue, editor) => {
                          setInputs((values) => ({
                            ...values,
                            ["info_es"]: newValue,
                          }));
                        }}
                      />
                    </label>
                  </div>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Link:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="text"
                      name="link"
                      value={inputs.link || ""}
                      onChange={handleChange}
                      placeholder={modalInfo.link}
                    ></input>
                  </label>
                  <label
                    style={{
                      marginLeft: "1vw",
                    }}
                  >
                    Image:
                    <input
                      style={{
                        marginLeft: "1vw",
                      }}
                      type="file"
                      name="img"
                      value={inputs.img || ""}
                      onChange={fileHandleChangeProfile}
                    />
                    <input
                      style={{
                        display: "none",
                      }}
                      type="text"
                      name="img_url"
                      value={inputs.img_url || ""}
                      onChange={handleChange}
                    />
                  </label>
                  <br></br>
                  <button
                    style={{
                      marginTop: "1vh",
                    }}
                    type="submit"
                    onClick={() => EquipaDataSet(inputs, modalInfo)}
                  >
                    Confirm
                  </button>
                </form>
              </Container>
            </Modal>
          </div>
          <div
            id="colaboradores"
            className="w3-container"
            style={{ marginBottom: "50vh", paddingTop: "5%" }}
          >
            <div style={{ display: "flex" }}>
              <h2>Colaboradores</h2>
            </div>
            {colaboradoresInfo.map((item) => (
              <ul>
                <li onClick={() => openModalColaboradores(item)}>
                  <p>Editar Colaboradores</p>
                </li>
              </ul>
            ))}

            <Modal
              isOpen={modalIsOpenColaboradores}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>
                <div>
                  <h3>
                    Colaboradores:
                    <div
                      style={{
                        height: "40vh",
                        width: "60vw",
                        overflowY: "auto",
                        border: "solid 1px grey",
                      }}
                      dangerouslySetInnerHTML={{ __html: modalInfo.nomes }}
                    ></div>
                  </h3>
                </div>
                <button onClick={() => openEditFormColaboradores(modalInfo)}>
                  Editar
                </button>
                <button onClick={() => ColaboradoresDelete(modalInfo)}>
                  Remover
                </button>
              </Container>
            </Modal>

            <Modal
              isOpen={modalIsOpenEditColaboradores}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              shouldCloseOnOverlayClick={false}
            >
              <Container>
                <button onClick={closeModal}>close</button>

                <form onSubmit={handleSubmit}>
                  <label
                    style={{
                      marginTop: "1vh",
                    }}
                  >
                    Colaboradores:
                    <Editor
                      initialValue={modalInfo.nomes}
                      type="text"
                      name="nomes"
                      value={inputs.nomes}
                      apiKey="wz8bzns5fe2g06vnnd7pz1auub1a0ecqyxsgw6u46coixrvj"
                      init={{
                        height: 200,
                        menubar: true,
                        plugins: [
                          "advlist autolink lists link image",
                          "charmap print preview anchor help",
                          "searchreplace visualblocks code",
                          "insertdatetime media table paste wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright | \
                bullist numlist outdent indent | help",
                      }}
                      onEditorChange={(newValue, editor) => {
                        setInputs((values) => ({
                          ...values,
                          ["nomes"]: newValue,
                        }));
                      }}
                    />
                  </label>
                  <br></br>
                  <button
                    style={{
                      marginTop: "1vh",
                    }}
                    type="submit"
                    onClick={() => ColaboradoresDataSet(inputs, modalInfo)}
                  >
                    Confirm
                  </button>
                </form>
              </Container>
            </Modal>
          </div>
        </div>
      </div>
    );
  } else {
    navigate("/admin");
    returnContent = <div></div>;
  }
  return returnContent;
}

export default AdminDashboard;
