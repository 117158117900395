import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function ProjetosItem(props) {

  const [titleDisplay, setTitleDisplay] = useState("block");
  const [dateDisplay, setDateDisplay] = useState("none");

  function hoverActionEnter() {
    setTitleDisplay("none");
    setDateDisplay("block");
  }

  function hoverActionLeave() {
    setTitleDisplay("block");
    setDateDisplay("none");
  }

  return (
    <Col style={{ marginBottom: "2.5%" }}>
      <div className="circle_projects">
        <div
          style={{
            textAlign: "center",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={"/projetos/" + props.project?.title}
          >
            <p
              onMouseEnter={() => hoverActionEnter()}
              onMouseLeave={() => hoverActionLeave()}
              style={{ fontFamily: "SlencoBold", fontSize: "50px" }}
            >
              <span
                id="title"
                style={{ display: titleDisplay, marginBottom: "-0.5rem" }}
              >
                <img
                  style={{
                    maxWidth: "235px",
                    maxHeight: "235px",
                    borderRadius: "50%",
                    marginBottom: "-0.5rem",
                  }}
                  src={props.project?.capa}
                />
              </span>
              <span
                id="date"
                style={{
                  display: dateDisplay,
                  fontFamily: "SlencoBold",
                  lineHeight: "50px",
                  marginBottom: "-2.5rem",
                  color: "white",
                }}
              >
                {props.project?.title} {props.project?.date}
              </span>
            </p>
          </Link>
        </div>
      </div>
    </Col>
  );
}

export default ProjetosItem;
