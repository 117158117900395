import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

function AdminAuth() {
  const navigate = useNavigate();

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        document.getElementById("email").value,
        document.getElementById("password").value
      );
      localStorage.setItem("user", user);
    } catch (error) {
    } finally {
      navigate("/admin/dashboard");
    }
  };

  return (
    <Container style={{width: "100%", height: "100%", }}>
      <div style={{margin: "auto", position: "fixed", top: "10%", left: "10%"}}>
        <Row>
        <h3 style={{marginBottom: "2%", fontFamily: "SlencoBold"}}>Access AdminPage </h3>
        <input type="email" placeholder="Email..." id="email" />
        <br></br>
        <input type="password" placeholder="Password..." id="password" />
        <br></br>
        <button onClick={login}>Access</button>
        </Row>
      </div>
    </Container>
  );
}

export default AdminAuth;
