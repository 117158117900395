import React from "react";
import FooterSecondary from "../../footer/FooterSecondary";
import Navbar2 from "../../navbar/Navbar2";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../projetos/projeto/Carousel.css";
import { useTranslation } from "react-i18next";
import img1 from "../../assets/aocaboteatro.png";
import img2 from "../../assets/aocaboteatro1.png";
import img3 from "../../assets/aocaboteatro2.png";
import img4 from "../../assets/aocaboteatro3.png";
import img5 from "../../assets/aocaboteatro4.png";
import img6 from "../../assets/aocaboteatro5.png";
import img7 from "../../assets/aocaboteatro6.png";
import img8 from "../../assets/aocaboteatro7.png";
import img9 from "../../assets/aocaboteatro8.png";
import img10 from "../../assets/aocaboteatro9.png";
import img11 from "../../assets/aocaboteatro10.png";
import img12 from "../../assets/aocaboteatro11.png";
import img13 from "../../assets/aocaboteatro12.jpg";
import img14 from "../../assets/aocaboteatro13.jpg";
import img15 from "../../assets/aocaboteatro14.jpg";

function Historia() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "#c2c2c2" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "#c2c2c2" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    autoplaySpeed: 3000,
    autoplay: true,
    cssEase: "linear",
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settings2 = {
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    autoplaySpeed: 3000,
    autoplay: true,
    cssEase: "linear",
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        {t("Historia.0")}
      </h1>
      <Container
        fluid
        style={{
          width: "100%",
          marginBottom: "10vh",
          padding: "0",
        }}
      >
        <Row style={{ width: "80%", margin: "auto" }}>
          <Col>
            <p
            className="text_historia"
              style={{
                textAlign: "center",
                padding: "30px",
                fontFamily: "SlencoBlack",
                fontSize: "16px",
                textAlign: "justify",
                textJustify: "inter-word",
                textAlignLast: "left",
              }}
            >
              {t("Historia.1")}
            </p>
          </Col>
        </Row>
        <Row className="desktop">
          <Col>
            <Slider {...settings}>
              <div>
                <img
                  src={img1}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img2}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img3}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img4}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img5}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img6}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img7}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img8}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img9}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img10}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img11}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img12}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img13}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img14}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img15}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
            </Slider>
          </Col>
        </Row>

        <Row className="mobile" style={{display: "none"}}>
          <Col>
            <Slider {...settings2}>
              <div>
                <img
                  src={img1}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img2}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img3}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img4}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img5}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img6}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img7}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img8}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img9}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img10}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img11}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img12}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img13}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img14}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
              <div>
                <img
                  src={img15}
                  style={{ maxHeight: "40vh", width: "auto" }}
                />
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Historia;
