import React, { useState, useEffect } from "react";
import Navbar2 from "../../navbar/Navbar2";
import RssReader from "../../assets/RssReader";
import EpisodeItem from "./EpisodeItem";
import { PODCASTSLIST } from "../../helper/Const";
import { useParams } from "react-router-dom";
import FooterSecondary from "../../footer/FooterSecondary";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

function PodcastDetail() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();

  let { podcast } = useParams();

  const [podcastData, setPodcastData] = useState(null);

  useEffect(() => {
    RssReader(PODCASTSLIST[podcast].url, setPodcastData);
  }, []);

  return (
    <div>
      <h1
      className="podcast_title"
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
        }}
      >
        {PODCASTSLIST[podcast].name}
      </h1>
      <Navbar2 />
      <Container
        fluid
        style={{
          width: "78%",
          paddingTop: "2vh",
          marginBottom: "10vh",
        }}
      >
        <p
          style={{
            fontFamily: "SlencoBold",
            fontSize: "20px",
            textAlign: "justify",
            textJustify: "inter-word",
            textAlignLast: "left",
            paddingBottom: "2.5vh",
          }}
        >
          {lang === "pt" ? PODCASTSLIST[podcast].desc_pt : lang === "en" ? PODCASTSLIST[podcast].desc_en : PODCASTSLIST[podcast].desc_es}
          
        </p>
        <Row lg={3} className="justify-content-md-center">
          {podcastData?.item[0].map((episode, key) => (
            <EpisodeItem
              episode={episode}
              EpNumber={podcastData.item.length - key}
              EpCover={podcastData.image.url}
            />
          ))}
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default PodcastDetail;
