import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Navbar2 from "../../navbar/Navbar2";
import FooterSecondary from "../../footer/FooterSecondary";

function EquipaMembro() {
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const { t } = useTranslation();
  let { name } = useParams();
  const [teamInfo, setTeamInfo] = useState([]);

  useEffect(() => {
    async function EquipaDataCall() {
      const q = query(collection(db, "Equipa"), where("Nome", "==", name));
      const querySnapshot = await getDocs(q);
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          name: doc.data().Nome,
          cargos: doc.data().Cargos,
          posição: doc.data().Posição,
          info_pt: doc.data().Informação.Informação_pt,
          info_en: doc.data().Informação.Informação_en,
          info_es: doc.data().Informação.Informação_es,
          link: doc.data().Link,
          imgUrl: doc.data().img,
        });
      });
      setTeamInfo(tempInfo);
    }
    EquipaDataCall();
  }, []);

  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        {t("Equipa.0")}
      </h1>
      <Container
        fluid
        style={{
          width: "80%",
          marginBottom: "10vh",
        }}
      >
        <Row>
          <Col style={{ marginTop: "5.5%" }}>
            <div className="circle_member">
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  borderRadius: "50%",
                }}
                src={teamInfo[0]?.imgUrl}
              ></img>
            </div>
            <p
              style={{
                fontFamily: "SlencoBlack",
                fontSize: "20px",
                textAlign: "center",
                marginTop: "15%",
              }}
            >
              <a
                style={{ color: "black" }}
                href={"https://" + teamInfo[0]?.link}
              >
                {teamInfo[0]?.link}
              </a>
            </p>
          </Col>
          <Col md={7} className="justify-content-end">
            <h3 className="nome_membro" style={{ fontFamily: "SlencoBold", fontSize: "48px" }}>
              {teamInfo[0]?.name}
            </h3>
            <p
            className="posicao_membro"
              style={{
                fontFamily: "Slenco",
                fontSize: "20px",
                textAlign: "right",
              }}
            >
              {teamInfo[0]?.posição}
            </p>
            <p
            className="cargo_membro"
              style={{
                fontFamily: "Slenco",
                fontSize: "20px",
                textAlign: "right",
                lineHeight: "0px",
              }}
            >
              {teamInfo[0]?.cargos}
            </p>
            <div
              style={{
                display: "inline-block",
                fontFamily: "SlencoLight",
                fontSize: "16px",
                marginTop: "5%",
                textAlign: "justify",
                textJustify: "inter-word",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  lang === "pt"
                    ? teamInfo[0]?.info_pt
                    : lang === "en"
                    ? teamInfo[0]?.info_en
                    : teamInfo[0]?.info_es,
              }}
            ></div>
          </Col>
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default EquipaMembro;
