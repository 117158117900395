import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function Navbar() {
  const [languageColor1, setLanguageColor1] = useState("#00000");
  const [languageColor2, setLanguageColor2] = useState("#00000");
  const [languageColor3, setLanguageColor3] = useState("#00000");
  const { t, i18n } = useTranslation();

  function handleClick(lang) {
    i18n.changeLanguage(lang);
  }
  return (
    <nav style={{ textAlign: "right", marginRight: "15%", marginTop: "2%", marginBottom: "2%", fontSize: "24px"}}>
      <span
        style={{ color: languageColor1, cursor: "pointer", fontFamily: "SlencoBlack"}}
        onMouseEnter={() => {
          setLanguageColor1("#ba3434");
        }}
        onMouseLeave={() => {
          setLanguageColor1("#000000");
        }}
        onClick={() => handleClick("pt")}
      >
        PT{" "}
      </span>
      <span style={{fontFamily: "SlencoBlack"}}>• </span>
      <span
        style={{ color: languageColor2, cursor: "pointer", fontFamily: "SlencoBlack" }}
        onMouseEnter={() => {
          setLanguageColor2("#ba3434");
        }}
        onMouseLeave={() => {
          setLanguageColor2("#000000");
        }}
        onClick={() => handleClick("en")}
      >
        EN{" "}
      </span>
      <span style={{fontFamily: "SlencoBlack"}}>• </span>
      <span
        style={{ color: languageColor3, cursor: "pointer", fontFamily: "SlencoBlack" }}
        onMouseEnter={() => {
          setLanguageColor3("#ba3434");
        }}
        onMouseLeave={() => {
          setLanguageColor3("#000000");
        }}
        onClick={() => handleClick("es")}
      >
        ES
      </span>
    </nav>
  );
}

export default Navbar;
