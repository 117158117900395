import React, { useState, useEffect } from "react";
import FooterSecondary from "../../footer/FooterSecondary";
import Navbar2 from "../../navbar/Navbar2";
import { useParams } from "react-router-dom";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../projeto/Carousel.css";

function Projeto() {
  let title = useParams();
  let i18n = useTranslation();
  let lang = i18n.i18n.language;
  const [projectsInfo, setProjectsInfo] = useState([]);

  useEffect(() => {
    async function ProjetosDataCall() {
      const q = query(
        collection(db, "Projetos"),
        where("Title", "==", title.title)
      );
      const querySnapshot = await getDocs(q);
      let tempInfo = [];
      querySnapshot.forEach((doc) => {
        tempInfo.push({
          id: doc.id,
          title: doc.data().Title,
          desc_pt: doc.data().Description?.Description_pt,
          desc_en: doc.data().Description?.Description_en,
          desc_es: doc.data().Description?.Description_es,
          ficha_pt: doc.data().Ficha?.Ficha_pt,
          ficha_en: doc.data().Ficha?.Ficha_en,
          ficha_es: doc.data().Ficha?.Ficha_es,
          imagens1: doc.data().Imagens.img1,
          imagens2: doc.data().Imagens.img2,
          imagens3: doc.data().Imagens.img3,
          imagens4: doc.data().Imagens.img4,
          imagens5: doc.data().Imagens.img5,
          imagens6: doc.data().Imagens.img6,
          date: doc.data().Date,
        });
      });
      setProjectsInfo(tempInfo);
    }
    ProjetosDataCall();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "#c2c2c2" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "#c2c2c2" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplaySpeed: 3000,
    autoplay: true,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      <Navbar2 />
      <h1
        style={{
          textAlign: "center",
          fontFamily: "SlencoBlack",
          fontSize: "80px",
          marginTop: "2%",
          marginBottom: "1%",
        }}
      >
        projetos
      </h1>
      <Container fluid style={{ width: "80%", marginBottom: "10vh" }}>
        <Row className="desktop">
          <Col>
            <Slider {...settings}>
              {projectsInfo[0]?.imagens1 !== "" &&
              projectsInfo[0]?.imagens1 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens1}
                    style={{
                      maxHeight: "65vh",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens2 !== "" &&
              projectsInfo[0]?.imagens2 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens2}
                    style={{
                      maxHeight: "65vh",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens3 !== "" &&
              projectsInfo[0]?.imagens3 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens3}
                    style={{
                      maxHeight: "65vh",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens4 !== "" &&
              projectsInfo[0]?.imagens4 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens4}
                    style={{
                      maxHeight: "65vh",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens5 !== "" &&
              projectsInfo[0]?.imagens5 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens5}
                    style={{
                      maxHeight: "65vh",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens6 !== "" &&
              projectsInfo[0]?.imagens6 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens6}
                    style={{
                      maxHeight: "65vh",
                      width: "auto",
                      margin: "auto",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </Slider>
          </Col>
        </Row>

        <Row className="mobile" style={{ display: "none" }}>
          <Col>
            <Slider {...settings}>
              {projectsInfo[0]?.imagens1 !== "" &&
              projectsInfo[0]?.imagens1 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens1}
                    style={{ maxWidth: "65vw", height: "auto", margin: "auto" }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens2 !== "" &&
              projectsInfo[0]?.imagens2 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens2}
                    style={{ maxWidth: "65vw", height: "auto", margin: "auto" }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens3 !== "" &&
              projectsInfo[0]?.imagens3 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens3}
                    style={{ maxWidth: "65vw", height: "auto", margin: "auto" }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens4 !== "" &&
              projectsInfo[0]?.imagens4 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens4}
                    style={{ maxWidth: "65vw", height: "auto", margin: "auto" }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens5 !== "" &&
              projectsInfo[0]?.imagens5 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens5}
                    style={{ maxWidth: "65vw", height: "auto", margin: "auto" }}
                  />
                </div>
              ) : (
                ""
              )}
              {projectsInfo[0]?.imagens6 !== "" &&
              projectsInfo[0]?.imagens6 !== null ? (
                <div>
                  <img
                    src={projectsInfo[0]?.imagens6}
                    style={{ maxWidth: "65vw", height: "auto", margin: "auto" }}
                  />
                </div>
              ) : (
                ""
              )}
            </Slider>
          </Col>
        </Row>

        <Row>
          <div style={{ marginTop: "7%", textAlign: "center" }}>
            <div>
              <h1
                style={{
                  fontFamily: "SlencoBold",
                  fontSize: "50px",
                  color: "black",
                }}
              >
                {projectsInfo[0]?.title} | {projectsInfo[0]?.date}
              </h1>
              <Col>
                <div
                  style={{
                    marginTop: "2%",
                    display: "inline-block",
                    fontFamily: "SlencoLight",
                    fontSize: "20px",
                    fontKerning: "-25px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "pt"
                        ? projectsInfo[0]?.desc_pt
                        : lang === "en"
                        ? projectsInfo[0]?.desc_en
                        : projectsInfo[0]?.desc_es,
                  }}
                ></div>
              </Col>
              <Col>
                <div
                  style={{
                    paddingBottom: "5%",
                    marginTop: "2%",
                    display: "inline-block",
                    fontFamily: "SlencoLight",
                    fontSize: "16px",
                    fontKerning: "-25px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      lang === "pt"
                        ? projectsInfo[0]?.ficha_pt
                        : lang === "en"
                        ? projectsInfo[0]?.ficha_en
                        : projectsInfo[0]?.ficha_es,
                  }}
                ></div>
              </Col>
            </div>
          </div>
        </Row>
      </Container>
      <FooterSecondary />
    </div>
  );
}

export default Projeto;
